'use strict';

import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from './i18n';


import VueSanitize from "vue-sanitize";
Vue.use(VueSanitize);

import VueMeta from 'vue-meta';
Vue.use(VueMeta);

const routes = [
	{ path: '/', component: () => import(/* webpackChunkName: "index" */ './index.vue') },
	{ path: '/card', component: () => import(/* webpackChunkName: "create-card" */ './create_card.vue') },
	{ path: '/card_created/:code', component: () => import(/* webpackChunkName: "card-created" */ './card_created_confirmation.vue') },
	{ path: '/message/:occasion/:recipient/:code', component: () => import(/* webpackChunkName: "create-message" */ './create_message.vue') },
	{ path: '/message/:code', component: () => import(/* webpackChunkName: "create-message" */ './create_message.vue') },
	{ path: '/message_created/:code', component: () => import(/* webpackChunkName: "message-created" */ './message_created_confirmation.vue') },
	{ path: '/card/:occasion/:recipient/:code/:admin_code', component: () => import(/* webpackChunkName: "finish-card" */ './finish_card.vue') },
	{ path: '/card/:code/:admin_code', component: () => import(/* webpackChunkName: "finish-card" */ './finish_card.vue') },
	{ path: '/rendered_card/:code/:admin_code', component: () => import(/* webpackChunkName: "rendered-card" */ './rendered_card.vue') },
	{ path: '/plane', component: () => import(/* webpackChunkName: "paper-plane" */ './paper_plane_animation.vue') },
	{ path: '/loader', component: () => import(/* webpackChunkName: "loader" */ './loader.vue') },
	{ path: '/metrics', component: () => import(/* webpackChunkName: "metrics" */ './metrics.vue') },
	{ path: '/faq', component: () => import(/* webpackChunkName: "faq" */ './faq.vue') },
];

const router = new VueRouter({
	routes,
	mode: 'history',
	base: '/',
});

Vue.use(VueRouter);

const app = new Vue({
	router,
	i18n,
}).$mount('#app');
